import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FavoriteService } from '../../services/favorite.service';
import { AuthService } from 'src/app/layouts/services/auth.service';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user;
  @Input() cardType: string = 'user';
  @Output() profile = new EventEmitter();
  @Output() appointment = new EventEmitter();
  @Output() favoriteChanged = new EventEmitter<number>(); // Émission de l'événement lorsque le favori change
  @Input() existAppointments: boolean = false; // Ajoutez cette ligne
  eventID;
  connectedUser: any;

  isFavorited: boolean = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private favoriteService: FavoriteService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    this.route.parent.params.subscribe(params => {
      if (params['id']) {
        this.eventID = params['id'];
      }
    });
    this.checkFavoriteStatus(this.user.userId);
  }

  checkFavoriteStatus(itemId: number) {
    this.favoriteService.isFavorite(this.connectedUser.ID, itemId).subscribe(response => {
      this.isFavorited = response.is_favorite;
    });
  }

  toggleFavorite(itemId: number) {
    if (this.isFavorited) {
      this.favoriteService.removeFavorite(this.connectedUser.ID, itemId).subscribe(() => {
        this.isFavorited = false;
        this.favoriteChanged.emit(itemId); // Émet l'événement lorsqu'un favori est retiré
      });
    } else {
      this.favoriteService.addFavorite(this.connectedUser.ID, itemId).subscribe(() => {
        this.isFavorited = true;
        this.favoriteChanged.emit(itemId); // Émet l'événement lorsqu'un favori est ajouté
      });
    }
  }

  isModalVisible = false;
  onChatClick(): void {
    this.isModalVisible = true;

    this.connectedUser = JSON.parse(localStorage.getItem('me'));
    const lang = localStorage.getItem('lang');

    const data = {
      clientId: '9d1a6c51-5e12-4c88-88df-54a4478dd8f2',
      clientSecret: 'sUgARnkDf5dRF96iQ7xgc3RQaGsBlpHMZlqrJnWB',
      email: this.connectedUser.email,
      provider: this.connectedUser.provider,
      providerId: this.connectedUser.provider_id,
      name: `${this.connectedUser.first_name} ${this.connectedUser.last_name}`,
      phone: this.connectedUser.phone,
      application: 'https://acp.sphere.contact'
    };

    this.authService.loginSSOBackend(data).subscribe(
      (response) => {
        const accessToken = response?.data?.token?.access_token;
        const refresh_token = response?.data?.token?.refresh_token;
        const expires_in = response?.data?.token?.expires_in;

        if (accessToken) {
          const redirectUrl = `https://acp.sphere.contact/?access_token=${accessToken}&refresh_token=${refresh_token}&expires_in=${expires_in}&lang=${lang}`;

          window.open(redirectUrl, '_blank');

          this.isModalVisible = false;
        } else {
          this.isModalVisible = false;
          console.error('Access token is missing in the response');
        }
      },
      (error) => {
        this.isModalVisible = false;
        console.error('Login failed:');
      }
    );
  }

  appointmentRequest(user) {
    this.appointment.emit(user);
  }

  viewProfile(user) {
    this.profile.emit(user);
  }

  chatRoom(id) {
    if (this.cardType == 'company') {
      this.router.navigate(['/account/chat'], { queryParams: { ref: id } });
    } else {
      this.router.navigate(['/room/event/' + this.eventID + '/chat'], { queryParams: { ref: id } });
    }
  }
}
